import React, { useEffect, useState } from 'react';
import { DataGrid, GridColumns, GridRowsProp } from '@mui/x-data-grid';
import { Box, Grid, Paper } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  CircularProgress, IconButton, LinearProgress, TextField,
} from '@mui/material';
import AppBarLayout from '../layouts/AppBarLayout';
import { useRootStore } from '../store/Context';
import { LIST_STULLER_USERS_BY_SHIP_TO } from '../graphql/queries/shipToQueries';

type shipTo = {
  loginId: string;
  email: string;
};

const ShipToView = observer(() => {
  document.title = 'Gems Admin Portal - ShipTo';
  const dashHeight = '720px';
  // searchTerm is passed down from SearchComponent > ModalComponent > DataGridComponent
  const { authenticationStore } = useRootStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const rowHeight = 40;
  const regex = /([\w-]+)/;

  const [listStullerUsersByShipTo, {
    called, loading, data, error,
  }] = useLazyQuery(
    LIST_STULLER_USERS_BY_SHIP_TO,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
      fetchPolicy: 'network-only',
      onError: (err: ApolloError) => {
        console.log(`Error on listStullerUsersByShipTo query: ${err.message}`);
        setRows([]);
      },
    },
  );

  useEffect(() => {
    console.log('useEffect: ');
    if (data && data.listStullerUsersByShipTo) {
      console.log('useEffect: has data');
      setRows(
        data.listStullerUsersByShipTo.map((element: shipTo, index: number) => ({
          id: index + 1,
          col1: element.loginId,
          col2: element.email,
        })),
      );
      setIsLoading(false);
    }
    if (error) {
      console.log(`useEffect: has error: ${error.message}`);
      setRows([]);
      setIsLoading(false);
      toast.error(error.message, { theme: 'colored' });
    }
    if (called && loading) {
      console.log('useEffect: loading');
      setIsLoading(true);
    }
  }, [data, error, called, loading, setIsLoading]);

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    if (regex.test(searchTerm)) {
      listStullerUsersByShipTo({
        variables: {
          shipTo: searchTerm,
        },
      });
    } else {
      setSearchTerm('');
    }
  };

  // Define columns of datagrid
  const columns = [
    { field: 'col1', headerName: 'Login Id', width: 350 },
    { field: 'col2', headerName: 'Email', width: 320 },
  ];

  return (
    <>
      <Grid container justifyContent="center">
        {/* DASH */}
        <Grid item xs={10} style={{ height: dashHeight }}>
          <div style={{ height: '95%', width: '100%' }}>
            <Box
              component="form"
              marginBottom="16px"
              justifyContent="center"
              alignItems="center"
            >
              <TextField
                type="search"
                label="Ship-To"
                size="small"
                value={searchTerm}
                onChange={(e) => {
                  e.preventDefault();
                  setSearchTerm(e.target.value);
                }}
              />
              <IconButton type="submit" color="primary" onClick={(e) => handleSearch(e)}>
                <SearchIcon />
              </IconButton>
              <IconButton type="submit" color="success" onClick={(e) => handleSearch(e)}>
                {isLoading ? <CircularProgress size="small" /> : <AutorenewIcon />}
              </IconButton>
            </Box>
            {isLoading ? (
              <>
                <LinearProgress />
                <DataGrid
                  rows={rows as GridRowsProp}
                  columns={columns as GridColumns}
                  rowHeight={rowHeight}
                />
              </>
            ) : (
              <>
                <DataGrid
                  rows={rows as GridRowsProp}
                  columns={columns as GridColumns}
                  rowHeight={rowHeight}
                />
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
});

export default ShipToView;
