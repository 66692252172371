import { ApolloError, useMutation } from '@apollo/client';
import {
  Grid,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Paper,
  Typography,
  LinearProgress,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { convertAppId } from '../../data/Helpers';
import {
  SET_USER_APP_OPTIONS,
} from '../../graphql/queries/userFeatureFlagsQueries';
import { FeatureFlagComponent } from './FeatureFlagComponent';
import { useRootStore } from '../../store/Context';
import { ISoftwareAccessResult } from '../../views/UserSoftwareAccess';

// NOTE for the checkbox part of this component:
//   the app data will show available features for the app,
//    but not whether the user has those features.
//    the users options array will show which are selected

interface IUserAppOptions {
  [featureName: string]: boolean;
}

export interface IUserAppFeatureFlagsComponentProps {
  userID: string;
  username: string;
  applicationID: string;
  availableFeatures: Array<string>;
  userFeatures: IUserAppOptions
}

export const UserAppFeatureFlagsComponent: FC<IUserAppFeatureFlagsComponentProps> = observer(
  ({
    userID, username, applicationID, availableFeatures, userFeatures,
  }) => {
    const { authenticationStore } = useRootStore();
    // TODO: maybe replace userAppOptions set state with a union of availableFeatures and userFeatures?
    const [userAppOptions, setUserAppOptions] = useState<IUserAppOptions>(userFeatures);
    const [loading, setLoading] = useState(false);
    const [setGQLUserAppOptions] = useMutation(SET_USER_APP_OPTIONS, {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
      fetchPolicy: 'network-only',
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' });
      },
    });

    const handleToggleFlag = (e: any) => {
      e.preventDefault();
      const [_, featureName] = e.target.id.split(':');
      setUserAppOptions((oldState) => (
        { ...oldState, ...{ [featureName]: e.target.checked } }
      ));
    };

    useEffect(() => {
      setLoading(true);
      const options = Object.entries(userAppOptions)
        .filter((v) => {
          const [_, flag] = v;
          // only include true flags
          return flag;
        })
        .map((v) => {
          const [feature, _] = v;
          // return the flag names that have a true value
          return feature;
        });
      setGQLUserAppOptions({
        variables: {
          userID,
          appID: applicationID,
          options,
        },
      }).then((data) => {
        console.log(JSON.stringify(data));
        toast.success(
          `${username}: ${convertAppId(applicationID)} options: ${JSON.stringify(options)}`,
        );
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }, [userAppOptions]);
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper sx={{ padding: 2, backgroundColor: '#212121' }}>
            <FormGroup>
              <FormLabel>
                <Typography variant="button" color="primary">
                  User Software Feature Flags
                </Typography>
              </FormLabel>
              {
                availableFeatures.map((feature) => {
                  // featureFlagComponent
                  // if the user options object doesn't have a flag for that app feature, set to false
                  if (!Object.prototype.hasOwnProperty.call(userAppOptions, feature)) {
                    return (
                      <FeatureFlagComponent
                        id={`${applicationID}:${feature}`}
                        flagName={feature}
                        initialFlag={false}
                        handleChange={handleToggleFlag}
                      />
                    );
                  }
                  return (
                    <FeatureFlagComponent
                      id={`${applicationID}:${feature}`}
                      flagName={feature}
                      initialFlag={userAppOptions[feature]}
                      handleChange={handleToggleFlag}
                    />
                  );
                })
              }
              <FormHelperText>administrate optional software features for a user</FormHelperText>
              <LinearProgress sx={{ display: loading ? 'block' : 'none' }} />
            </FormGroup>
          </Paper>
        </Grid>
      </Grid>
    );
  },
);
