import {
  Grid,
  LinearProgress,
  Typography,
  CircularProgress,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
  FC, useEffect, useState,
} from 'react';
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import crypto from 'crypto';
import SearchBarComponent from '../components/SearchBarComponent';
import { AccordionSection } from '../components/accordionSection';
import { counterSketch, counterSketchStreaming, matrixGold3 } from '../data/Helpers';
import {
  GET_APP_DATA,
  GET_USER_FEATURE_FLAGS,
} from '../graphql/queries/userFeatureFlagsQueries';
import {
  UserAppFeatureFlagsComponent,
} from '../components/UserFeatureFlags/userAppFeatureFlagsComponent';
import { useRootStore } from '../store/Context';

interface ISoftwareFlagsMenuProps {
  handleSearchChange: (e:any) => void;
  handleSearch: (e: any) => void;
  searchValue: string;
  loading: boolean;
}
const SoftwareFlagsMenu: FC<ISoftwareFlagsMenuProps> = observer(
  ({
    handleSearch, handleSearchChange, searchValue, loading,
  }) => (
    <>
      <SearchBarComponent
        handleSearchChange={handleSearchChange}
        handleSearch={handleSearch}
        searchTextValue={searchValue}
        placeHolderSearchText="Username"
        elementId="searchUsersSoftwareFlags"
      />
      <LinearProgress style={{ display: loading ? 'block' : 'none' }} />
    </>
  ),
);

interface IAppOptionsResult {
  applicationID: string;
  applicationName: string;
  optionalFeatures: {
    featureOptionName: string;
    features: {
      featureID: string;
      featureName: string;
    }[]
  }[]
}

interface AppFeatureOptions {
  [applicationID: string] : Array<string>;
}

const convertGqlAppData = (gqlAppData: Array<IAppOptionsResult>): AppFeatureOptions => {
  const appOptions: AppFeatureOptions = {};
  for (let i = 0; i < gqlAppData.length; i += 1) {
    const { applicationID, optionalFeatures } = gqlAppData[i];
    appOptions[applicationID] = optionalFeatures.map((v) => v.featureOptionName);
  }
  return appOptions;
};

interface IGQLUserSoftwareOptions {
  appId: string;
  // options is a list of feature names for an app
  options: string[];
}

interface IUserFeatures {
  [featureName: string]: boolean;
}

interface IUserAppData {
  [applicationID: string]: IUserFeatures;
}
const convertGQLUserAppOptions = (gqlUserData: IGQLUserSoftwareOptions[]): IUserAppData => {
  const userAppData: IUserAppData = {};
  for (let i = 0; i < gqlUserData.length; i += 1) {
    const { appId, options } = gqlUserData[i];
    userAppData[appId] = options.reduce((acc: IUserFeatures, v) => {
      acc[v] = true;
      return acc;
    }, {});
  }
  return userAppData;
};

const UserFeatureFlags: React.FC = observer(() => {
  const { authenticationStore } = useRootStore();
  const [username, setUsername] = useState<string>('');
  const [userData, setUserData] = useState<IUserAppData>({});
  const [appData, setAppData] = useState<AppFeatureOptions>({});
  const [showControls, setShowControls] = useState<boolean>(false);
  const [appDataReady, setAppDataReady] = useState<boolean>(false);
  const [userID, setUserId] = useState<string>('');
  const { data: gqlAppData, loading: getAppDataLoading } = useQuery(
    GET_APP_DATA,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
      fetchPolicy: 'network-only',
      onError: (err: ApolloError) => {
        toast.error(`Error getting app data: ${err.message}`, { theme: 'colored' });
      },
    },
  );
  const [
    getUserFeatureFlags,
    { data: gqlUserData, loading: getUserFeatureFlagsLoading },
  ] = useLazyQuery(
    GET_USER_FEATURE_FLAGS,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
      fetchPolicy: 'network-only',
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' });
      },
    },
  );
  const resetView = () => {
    setUserData({});
    setShowControls(false);
  };
  const handleSearchChange = (e: any) => {
    setUsername(e.target.value.toLowerCase());
    resetView();
  };
  const handleSearch = (e: any) => {
    e.preventDefault();
    getUserFeatureFlags({
      variables: {
        username: username.toLowerCase().trim(),
      },
    });
  };

  useEffect(() => {
    if (gqlAppData) {
      // convert the app data from graphql into something we can use.
      console.log(JSON.stringify(gqlAppData));
      setAppData(
        convertGqlAppData(gqlAppData.listApplications.items),
      );
    }
  }, [gqlAppData]);

  useEffect(() => {
    if (gqlUserData) {
      setUserData(
        convertGQLUserAppOptions(gqlUserData.getGemsUser.userSoftwareOptions),
      );
      setUserId(gqlUserData.getGemsUser.userID);
      setShowControls(true);
    }
  }, [gqlUserData]);

  useEffect(() => {
    if (appData) {
      console.log(`appdata: ${JSON.stringify(appData)}`);
      setAppDataReady(true);
    }
  }, [appData]);

  // if (getAppDataLoading || !appDataReady) {
  //   return (
  //     <LinearProgress style={{ display: 'block' }} />
  //   );
  // }
  return (
    (getAppDataLoading || !appDataReady)
      ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )
      : (
        <div style={{ margin: '25px' }}>
          {console.log('hello')}
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} spacing={5}>
              <SoftwareFlagsMenu
                handleSearch={handleSearch}
                handleSearchChange={handleSearchChange}
                searchValue={username}
                loading={getUserFeatureFlagsLoading}
              />
            </Grid>
            {showControls ? (
              <Grid item xs={12}>
                <AccordionSection
                  sectionName="MatrixGold"
                  content={[
                    <UserAppFeatureFlagsComponent
                      userID={userID}
                      username={username}
                      applicationID={matrixGold3}
                      availableFeatures={appData[matrixGold3]}
                      userFeatures={Object.prototype.hasOwnProperty.call(userData, matrixGold3)
                        ? userData[matrixGold3]
                        : {}}
                    />,
                  ]}
                />
                <AccordionSection
                  sectionName="CounterSketch"
                  content={[
                    <UserAppFeatureFlagsComponent
                      userID={userID}
                      username={username}
                      applicationID={counterSketch}
                      availableFeatures={appData[counterSketch]}
                      userFeatures={Object.prototype.hasOwnProperty.call(userData, counterSketch)
                        ? userData[counterSketch]
                        : {}}
                    />,
                  ]}
                />
                <AccordionSection
                  sectionName="CounterSketch Streaming"
                  content={[
                    <UserAppFeatureFlagsComponent
                      userID={userID}
                      username={username}
                      applicationID={counterSketchStreaming}
                      availableFeatures={appData[counterSketchStreaming]}
                      userFeatures={Object.prototype.hasOwnProperty.call(userData, counterSketchStreaming)
                        ? userData[counterSketchStreaming]
                        : {}}
                    />,
                  ]}
                />
              </Grid>
            ) : (
              <Grid item xs={10}>
                <Typography align="center" color="text.secondary">
                  Search for a valid username to begin.
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      )
  );
});

export default UserFeatureFlags;
