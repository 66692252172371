import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET_USER_ROLES } from '../../graphql/queries/roleQueries';
import { useRootStore } from '../../store/Context';
import ForbiddenView from '../../views/ForbiddenView';

export interface IRoleRouteProps {
  requiredRole: Array<string>;
}

type userRole = {
  role: string;
};

const RoleRequiredRoute: FunctionComponent<IRoleRouteProps> = observer(
  ({ children, requiredRole }) => {
    const { authenticationStore, userStore, rolesStore } = useRootStore();
    const [validRolesFound, setValidRolesFound] = useState<boolean>(false);
    // Query current user roles
    const { data, loading, error } = useQuery(GET_USER_ROLES, {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
      variables: {
        userId: userStore.getUsernameHash,
      },
    });

    if (error) {
      console.log(error);
    }

    useEffect(() => {
      if (data) {
        // take the intersection of the user's roles, and the roles required for this route,
        // second filter removes duplicates if any
        const matchedRequiredRolesCollection: Array<string> = data.listUserRoles.items
          .filter((value: any) => requiredRole.includes(value.role))
          .filter((e: any, i: any, c: string | any[]) => c.indexOf(e) === i);
        console.log(`roles: ${JSON.stringify(matchedRequiredRolesCollection)}`);
        rolesStore.setRoles = matchedRequiredRolesCollection.map((item: any) => item.role);
        console.log(`roles: ${JSON.stringify(rolesStore.getRoles)}`);
        if (matchedRequiredRolesCollection.length > 0) {
          setValidRolesFound(true);
        }
      }
    }, [validRolesFound, setValidRolesFound, data]);

    return (
      <>
        {loading ? (
          <LinearProgress />
        ) : (
          <>{validRolesFound ? <>{children}</> : <ForbiddenView />}</>
        )}
      </>
    );
  },
);

export default RoleRequiredRoute;
