import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { DataGrid, GridColumns, GridRowsProp } from '@mui/x-data-grid';
import { ApolloError, useLazyQuery } from '@apollo/client';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { CircularProgress, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { useRootStore } from '../store/Context';
import { LIST_ACCOUNT_RECORDS } from '../graphql/queries/accountQueries';

type accountBaseRecord = {
  AccountNumber: string;
  accountID: string;
  CompanyName: string;
  PhoneNumber: string;
  AddressLine1: string;
  City: string;
  State: string;
  Zip: string;
  Country: string;
  trialsEnabled: boolean;
  trialsIssuedForAccount: number;
};

const AccountsView = observer(() => {
  document.title = 'Gems Admin Portal - Dashboard';
  const dashHeight = '720px';
  // searchTerm is passed down from SearchComponent > ModalComponent > DataGridComponent
  const { authenticationStore } = useRootStore();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const rowHeight = 40;

  const [listAccountBaseRecords, {
    called, loading, data, error,
  }] = useLazyQuery(
    LIST_ACCOUNT_RECORDS,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
      fetchPolicy: 'network-only',
      onError: (err: ApolloError) => {
        console.log(`Error on listAccountBaseRecords query: ${err.message}`);
        setRows([]);
      },
    },
  );

  useEffect(() => {
    console.log('useEffect: ');
    if (data && data.listAccountBaseRecords) {
      console.log('useEffect: has data');
      setRows(
        data.listAccountBaseRecords.items.map(
          (element: accountBaseRecord, index: number) => ({
            id: index + 1,
            col1: element.AccountNumber,
            col2: element.accountID,
            col3: element.CompanyName,
            col4: element.trialsIssuedForAccount,
            col5: element.PhoneNumber,
            col6: element.City,
            col7: element.State,
            col8: element.Zip,
            col9: element.Country,
            col10: element.AddressLine1,
          }),
        ),
      );
      setIsLoading(false);
    }
    if (error) {
      console.log(`useEffect: has error: ${error.message}`);
      setRows([]);
      setIsLoading(false);
      toast.error(error.message, { theme: 'colored' });
    }
    if (called && loading) {
      console.log('useEffect: loading');
      setIsLoading(true);
    }
  }, [data, error, called, loading, setIsLoading]);

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    listAccountBaseRecords();
    console.log('handling search...');
  };

  // Define columns of datagrid
  const columns = [
    { field: 'col1', headerName: 'Account #', width: 150 },
    { field: 'col2', headerName: 'Account ID', width: 150 },
    { field: 'col3', headerName: 'Company', width: 240 },
    { field: 'col4', headerName: 'Trials #', width: 80 },
    { field: 'col5', headerName: 'Phone #', width: 120 },
    { field: 'col6', headerName: 'City', width: 200 },
    { field: 'col7', headerName: 'State', width: 200 },
    { field: 'col8', headerName: 'Zip', width: 120 },
    { field: 'col9', headerName: 'Country', width: 120 },
    { field: 'col10', headerName: 'Address Line', width: 350 },
  ];

  return (
    <>
      <Grid container justifyContent="center">
        {/* DASH */}
        <Grid item xs={10} style={{ height: dashHeight }}>
          <div style={{ height: '95%', width: '100%' }}>
            <Box marginBottom="16px" justifyContent="center" alignItems="center">
              <IconButton type="submit" color="success" onClick={(e) => handleSearch(e)}>
                {isLoading ? <CircularProgress /> : <AutorenewIcon />}
              </IconButton>
            </Box>
            <DataGrid
              rows={rows as GridRowsProp}
              columns={columns as GridColumns}
              rowHeight={rowHeight}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
});

export default AccountsView;
