import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

export const validRoles = {
  USERSREAD: 'USERSREAD',
  USERSWRITE: 'USERSWRITE',
  ACCOUNTSREAD: 'ACCOUNTSREAD',
  ACCOUNTSWRITE: 'ACCOUNTSWRITE',
  ADMINFULLACCESS: 'ADMINFULLACCESS', // Allows modifing admin tool user roles
  ADMINBASICACCESS: 'ADMINBASICACCESS', // all basic roles, minus super admin stuff
};

export default class RolesStore {
  private rootStore: RootStore;

  // collection of role names the user has assigned
  private roles: Array<string>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.roles = [];
    makeAutoObservable(this);
  }

  // add role to role collection
  addRole = (roleName: string): void => {
    if (this.validateRole(roleName)) {
      this.roles.push(roleName);
    }
  };

  set setRoles(roles: string[]) {
    this.roles = roles;
  }

  // returns true if role is in collection, false otherwise
  checkForRole = (roleName: string): boolean => this.roles.indexOf(roleName) !== -1;

  // returns all assigned roles
  get getRoles(): Array<string> {
    return this.roles;
  }

  // validates that the given roleName is an accepted role name.
  private validateRole = (roleName: string): boolean => {
    if (roleName in validRoles) {
      return true;
    }
    throw new Error(`${roleName} is not a valid role.`);
  };
}
