import { makeAutoObservable } from 'mobx';
import AuthenticationStore from './AuthenticationStore';
import RolesStore from './RolesStore';
import UserStore from './UserStore';

export class RootStore {
  authenticationStore: AuthenticationStore;

  userStore: UserStore;

  rolesStore: RolesStore;

  constructor() {
    makeAutoObservable(this);
    this.authenticationStore = new AuthenticationStore(this);
    this.userStore = new UserStore(this);
    this.rolesStore = new RolesStore(this);
  }
}
