/* eslint-disable no-restricted-globals */
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGrid, GridColDef, GridOverlay, GridRowModel,
} from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import PlusIcon from '@mui/icons-material/ArrowUpward';
import UnknownIcon from '@mui/icons-material/Help';
import MinusIcon from '@mui/icons-material/ArrowDownward';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import NoChangeIcon from '@mui/icons-material/FeaturedPlayList';
import {
  LIST_ACCOUNT_ENTITLEMENTS,
  OFFSET_CREATE_ENTITLEMENT,
} from '../graphql/queries/accountQueries';
import { useRootStore } from '../store/Context';
import { IAccountEntitlementsResult, IAppRecords } from '../graphql/types/accountTypes';
import {
  applicationCollection,
  applicationCollectionData,
  convertAppId,
  convertShipToBillTo,
  isBuyingGroupBillto,
} from '../data/Helpers';
import SearchBarComponent from '../components/SearchBarComponent';

interface IManageAccountEntitlementMenuProps {
  handleSearchChange: (e: any) => void;
  handleSearch: (e: any) => void;
  account: string;
}
const ManageAccountEntitlementMenu: FC<IManageAccountEntitlementMenuProps> = observer(
  ({ handleSearch, handleSearchChange, account }) => (
    <SearchBarComponent
      handleSearch={handleSearch}
      handleSearchChange={handleSearchChange}
      searchTextValue={account}
      placeHolderSearchText="Search for Ship-To Account Number (141833-000001)"
    />
  ),
);

interface IManageAccountEntitlementsViewProps {
  writeAccessRole: string[];
}
const ManageAccountEntitlementsView: FC<IManageAccountEntitlementsViewProps> = observer(({ writeAccessRole }) => {
  const { authenticationStore, rolesStore } = useRootStore();
  const [account, setAccount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [readOnlyAccess, setReadOnlyAccess] = useState(true);
  const [applicationOwnedAmounts, setApplicationOwnedAmounts] = useState<{
    [key: string]: number;
  }>({});
  const [initialApplicationOwnedAmounts, setInitialApplicationOwnedAmounts] = useState<{
    [key: string]: number;
  }>({});
  const [applicationOwnedExpiration, setApplicationOwnedExpiration] = useState<{
    [key: string]: number | null;
  }>({});
  const [initialApplicationOwnedExpiration, setInitialApplicationOwnedExpiration] = useState<{
    [key: string]: number | null;
  }>({});

  const [queryAccountEntitlements, { loading: accountEntitlementsLoading, data }] = useLazyQuery(LIST_ACCOUNT_ENTITLEMENTS, {
    context: {
      headers: {
        Authorization: `Bearer ${authenticationStore.getAccessToken}`,
      },
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' });
    },
    fetchPolicy: 'network-only',
  });

  const [mutateCreateOffsetEntitlement] = useMutation(OFFSET_CREATE_ENTITLEMENT, {
    context: {
      headers: {
        Authorization: `Bearer ${authenticationStore.getAccessToken}`,
      },
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' });
    },
    onCompleted: (resp: any) => {
      const toastMessage = `${convertAppId(resp.offsetClaims.appId)} updated to ${resp.offsetClaims.amount}`;
      toast.success(toastMessage, { theme: 'colored' });
    },
  });

  const RenderApplicationOffsetControl = ({ amount, appId }: { amount: number; appId: string; }) => {
    const [value, setValue] = useState<number>(amount);
    return (
      <TextField
        disabled={readOnlyAccess}
        id="outlined-number"
        label="Amount"
        type="number"
        size="small"
        value={value}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        onChange={(e: any) => {
          setValue(e.target.value);
          if (e.target.value < 0) {
            setValue(0);
          }
          setApplicationOwnedAmounts((prevState) => ({
            ...prevState,
            [appId]: Number(e.target.value) < 0 ? 0 : Number(e.target.value),
          }));
        }}
      />
    );
  };

  const RenderEntitlementAccountChange = ({ appId }: { appId: string }) => {
    const amountDiff = (Number(applicationOwnedAmounts[appId]) ?? 0) - (Number(initialApplicationOwnedAmounts[appId]) ?? 0);
    let expirationDiff = (Number(applicationOwnedExpiration[appId]) ?? 0) - (Number(initialApplicationOwnedExpiration[appId]) ?? 0);
    expirationDiff /= 86400; // Converted to days
    const chipColor = (diff: number) => {
      if (isNaN(diff)) {
        return 'info';
      }
      if (diff === 0) {
        return 'primary';
      }
      if (diff > 0) {
        return 'success';
      }
      return 'error';
    };
    const chipIcon = (diff: number) => {
      if (isNaN(diff)) {
        return <UnknownIcon fontSize="small" />;
      }
      if (diff === 0) {
        return <NoChangeIcon fontSize="small" />;
      }
      if (diff > 0) {
        return <PlusIcon fontSize="small" />;
      }
      return <MinusIcon fontSize="small" />;
    };

    if (loading) {
      return <CircularProgress color="secondary" />;
    }

    if (amountDiff === 0 && expirationDiff === 0) {
      return <Typography variant="overline">No Change</Typography>;
    }

    return (
      <>
        {amountDiff !== 0 && (
          <Chip
            label={`${amountDiff} license/s`}
            color={chipColor(amountDiff)}
            icon={chipIcon(amountDiff)}
            variant="filled"
          />
        )}
        {expirationDiff !== 0 && (
          <Chip
            label={`${expirationDiff.toFixed(2)} day/s`}
            color={chipColor(expirationDiff)}
            icon={chipIcon(expirationDiff)}
            variant="filled"
          />
        )}
      </>
    );
  };

  const RenderCreationDate = ({ date }: { date: number }) => {
    if (!date || date === 0) {
      return (<></>);
    }
    const formattedDate = new Date(date * 1000).toISOString().slice(0, -8);

    return (
      <TextField
        id="outlined-number"
        type="datetime-local"
        size="small"
        value={formattedDate}
        disabled
      />
    );
  };

  const RenderExpirationDate = ({ date, appId }: { date: number, appId: string }) => {
    const formattedDate = new Date(date * 1000).toISOString().slice(0, -8);
    const [value, setValue] = useState<string>(formattedDate);

    if (!applicationCollectionData[appId as keyof typeof applicationCollectionData].isSubscription) {
      return (<></>);
    }

    return (
      <TextField
        disabled={readOnlyAccess}
        id="outlined-number"
        type="datetime-local"
        size="small"
        value={value}
        onChange={(e: any) => {
          setValue(e.target.value);
          const newDate = new Date(e.target.value);
          const expirationDate = Math.trunc(newDate.getTime() / 1000 - newDate.getTimezoneOffset() * 60);
          setApplicationOwnedExpiration((prevState) => ({
            ...prevState,
            [appId]: expirationDate,
          }));
        }}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'app_name',
      headerName: 'Application',
      width: 200,
      sortable: false,
    },
    {
      field: 'offset_control',
      headerName: 'License Amount Owned',
      renderCell: (params) => RenderApplicationOffsetControl(params.value),
      width: 250,
      sortable: false,
    },
    {
      field: 'creation_date',
      headerName: 'Creation Date',
      renderCell: (params) => RenderCreationDate(params.value),
      width: 250,
      sortable: false,
    },
    {
      field: 'expiration_date',
      headerName: 'Expiration Date',
      renderCell: (params) => RenderExpirationDate(params.value),
      width: 250,
      sortable: false,
    },
    {
      field: 'difference',
      headerName: 'Change',
      renderCell: (params) => RenderEntitlementAccountChange(params.value),
      width: 300,
      sortable: false,
    },
  ];

  const clearEntitlementStateData = () => {
    setInitialApplicationOwnedAmounts({});
    setApplicationOwnedAmounts({});
    setInitialApplicationOwnedExpiration({});
    setApplicationOwnedExpiration({});
  };

  const refreshRowDataClean = () => {
    clearEntitlementStateData();
    queryAccountEntitlements({ variables: { accountId: convertShipToBillTo(account) } });
  };

  const handleSearchChange = (e: any) => {
    setAccount(e.target.value);
    setRows([]);
    clearEntitlementStateData();
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    // fudging this for now. the first number in {1,6} probably needs to be more than 1.
    // It used to be {6,}, which matches exactly 6 digits, but we found accounts that
    // have 5 digits before '-' (such as 67350-000000), so I relaxed the regex to at least 1,
    // but up to 6 digits.
    const re = RegExp('[\\d]{1,6}-[\\d]{6,}$');
    const trimmedAccount = account.trim();
    if (!re.test(trimmedAccount)) {
      toast.error('Invalid Ship To format provided', { theme: 'colored' });
      return;
    }
    if (isBuyingGroupBillto(trimmedAccount)) {
      toast.error(
        `Account ${trimmedAccount} is an buying group bill to. Ship to must be provided for buying groups.`,
        { theme: 'colored' },
      );
      return;
    }
    queryAccountEntitlements({ variables: { accountId: convertShipToBillTo(trimmedAccount) } });
  };

  const handleCreateEntitlementsUsingOffsets = async () => {
    try {
      setLoading(true);
      await Promise.all(
        applicationCollection.map((appId) => {
          const newAmount = isNaN(Number(applicationOwnedAmounts[appId]))
            ? 0
            : Number(applicationOwnedAmounts[appId]);
          const oldAmount = isNaN(Number(initialApplicationOwnedAmounts[appId]))
            ? 0
            : Number(initialApplicationOwnedAmounts[appId]);

          let finalAmount = 0;
          if (newAmount < oldAmount) {
            finalAmount = (oldAmount - newAmount) * -1;
          } else if (newAmount > oldAmount) {
            finalAmount = newAmount - oldAmount;
          }

          let newExpiration = 0;
          let oldExpiration = 0;
          if (applicationCollectionData[appId].isSubscription) {
            newExpiration = isNaN(Number(applicationOwnedExpiration[appId]))
              ? 0
              : Number(applicationOwnedExpiration[appId]);
            oldExpiration = isNaN(Number(initialApplicationOwnedExpiration[appId]))
              ? 0
              : Number(initialApplicationOwnedExpiration[appId]);
          }
          console.log(`appId: ${appId} final amount: ${finalAmount}`);
          if (finalAmount !== 0 || (newExpiration !== 0 && newExpiration !== oldExpiration)) {
            return mutateCreateOffsetEntitlement({
              variables: {
                accountID: convertShipToBillTo(account),
                appId,
                amount: finalAmount,
                expiry: Math.round(newExpiration),
              },
            });
          }
          return null;
        }),
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line max-len
  const createMissingRecords = (missingRecords: Array<string>): Array<IAppRecords> => missingRecords.map((item) => ({
    appId: item,
    amount: 0,
    accountID: convertShipToBillTo(account),
    creationDate: null,
    expirationDate: null,
  }));

  // Finds records that are missing from accounts
  const generateMissingRecordsIfAny = (ownedRecords: Array<string>): Array<IAppRecords> => {
    const missing = applicationCollection.filter(
      (obj) => !ownedRecords.includes(obj),
    );
    return createMissingRecords(missing);
  };

  const populateRowData = (queryObject: IAccountEntitlementsResult) => {
    if (queryObject.items.length === 0) {
      // toast.error('Account not found.', { theme: 'colored' });
      // return;
    }
    const missingAppRecordCollection = generateMissingRecordsIfAny(
      queryObject.items.map((item) => item.appId),
    );
    let combinedMissingAndOwnedRecords: Array<IAppRecords> = [
      ...missingAppRecordCollection,
      ...queryObject.items,
    ];
    combinedMissingAndOwnedRecords = combinedMissingAndOwnedRecords.filter(
      (record) => applicationCollection.includes(record.appId),
    );

    const newRows: GridRowModel[] = [];
    // populate the initial amounts of each record.
    combinedMissingAndOwnedRecords.forEach((item, index) => {
      setInitialApplicationOwnedAmounts((prevState) => ({
        ...prevState,
        [item.appId]: Number(item.amount),
      }));
      // set this to initial amounts, on component load this should be
      // what they currently own
      setApplicationOwnedAmounts((prevState) => ({
        ...prevState,
        [item.appId]: Number(item.amount),
      }));

      const initialExpiration = !item.expirationDate || item.expirationDate === 0 ? Date.now() / 1000 : item.expirationDate;

      setInitialApplicationOwnedExpiration((prevState) => ({
        ...prevState,
        [item.appId]: initialExpiration,
      }));

      setApplicationOwnedExpiration((prevState) => ({
        ...prevState,
        [item.appId]: initialExpiration,
      }));

      newRows.push({
        id: applicationCollectionData[item.appId].sortIndex,
        app_name: convertAppId(item.appId),
        offset_control: { amount: Number(item.amount), appId: item.appId },
        difference: { appId: item.appId },
        creation_date: { date: item.creationDate },
        expiration_date: { date: initialExpiration, appId: item.appId },
        key: item.appId,
      });
    });
    setRows(newRows.sort((a, b) => a.id - b.id));
  };

  const StyledGridOverlay = styled(GridOverlay)(() => ({
    flexDirection: 'column',
  }));

  const CustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <Box>
        <ScreenSearchDesktopIcon fontSize="large" color="secondary" />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="overline">
          Search for a valid Ship To account number to load results.
        </Typography>
      </Box>
    </StyledGridOverlay>
  );

  useEffect(() => {
    // check user roles to see if they have read only access
    const roles = rolesStore.getRoles;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < roles.length; i++) {
      const userRole = roles[i];
      console.log(`userRole: ${userRole}`);
      const found = writeAccessRole.findIndex((element) => element === userRole);
      if (found > -1) {
        console.log('write access found');
        setReadOnlyAccess(false);
        break;
      }
      console.log('write access not found');
    }
    if (data) {
      populateRowData(data.listAccountAppRecords);
    }
    if (accountEntitlementsLoading) {
      setLoading(accountEntitlementsLoading);
    } else {
      setLoading(false);
    }
  }, [data, accountEntitlementsLoading]);

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <ManageAccountEntitlementMenu
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          account={account}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          components={{ NoRowsOverlay: CustomNoRowsOverlay }}
          loading={loading}
          rows={rows}
          columns={columns}
          autoHeight
          disableSelectionOnClick
          hideFooter
          disableColumnSelector
          disableColumnFilter
        />
      </Grid>
      <Grid item xs={2} alignSelf="flex-end">
        <Button
          variant="contained"
          fullWidth
          color="primary"
          disabled={readOnlyAccess || rows.length === 0}
          onClick={async (e: any) => {
            try {
              e.preventDefault();
              await handleCreateEntitlementsUsingOffsets();
              refreshRowDataClean();
            } catch (err) {
              console.log(err);
            }
          }}
        >
          Save Changes
        </Button>
      </Grid>
    </Grid>
  );
});

export default ManageAccountEntitlementsView;
