import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGrid, GridColDef, GridOverlay, GridRowModel,
} from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import SearchIcon from '@mui/icons-material/SearchTwoTone';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  CREATE_USER_WITH_ROLE,
  DELETE_USER_WITH_ROLE,
  GET_GEMS_USER_ROLES,
  IS_VALID_GEMS_USER,
} from '../graphql/queries/gemsQueries';
import { useRootStore } from '../store/Context';

enum gemsUserGroups {
  ADMIN = 'ADMIN',
  OPS = 'OPS',
  DEV = 'DEV',
  SUPPORT = 'SUPPORT',
  SUPPORTREADONLY = 'SUPPORTREADONLY',
}
// All the current roles, this should be in a better place
// and handled better, like fetching these from the service itself
// currently ADMIN is god-mode: all of the below actions plus adding users to
//  admin portal and setting roles.
// DEV is setting user flag access (allowing users to turn on and off DEV and RC)
// SUPPORT is user trial administration as well as account entitlement administration.
// SUPPORTREADONLY has access to the places SUPPORT has, but can not change anything.
// OPS is TBD for read only access to reports and such.
const ROLES = ['ADMIN', 'OPS', 'DEV', 'SUPPORT', 'SUPPORTREADONLY'];

// GQL Query Response Interface
interface IListGemsUsersRolesResults {
  userId: string;
  username: string;
  role: string;
}
interface IAddGemsUserProps {
  dialogOpen: boolean;
  handleAddUserDialog: (isOpen: boolean) => void;
  handleAddUserWithRole: (username: string, role: string) => void;
  refreshAllUsers: () => void;
}
const AddGemsUserDialog: React.FC<IAddGemsUserProps> = ({
  dialogOpen,
  handleAddUserDialog,
  handleAddUserWithRole,
  refreshAllUsers,
}) => {
  const [username, setUsername] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const { authenticationStore } = useRootStore();
  const [isValidGemsUsername, { data: isValidGemsUsernameData, loading }] = useLazyQuery(
    IS_VALID_GEMS_USER,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
    },
  );
  const handleRoleValueChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };
  const handleUsernameValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value.toLowerCase());
  };
  const closeDialog = () => {
    setUsername('');
    setRole('');
    handleAddUserDialog(false);
  };
  useEffect(() => {
    if (isValidGemsUsernameData && isValidGemsUsernameData.isValidStullerUser) {
      handleAddUserWithRole(username, role);
      refreshAllUsers();
      closeDialog();
    }
  }, [isValidGemsUsernameData]);
  return (
    <Dialog
      open={dialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add New GEMS User</DialogTitle>
      <DialogContent sx={{ width: 450 }}>
        <Grid container>
          <Grid item xs={6} margin={2}>
            <TextField
              id="username"
              label="Username"
              value={username}
              onChange={handleUsernameValueChange}
            />
          </Grid>
          <Grid item xs={4} margin={2}>
            <FormControl fullWidth>
              <InputLabel id="role-group-label">Role Group</InputLabel>
              <Select
                variant="outlined"
                label="User Role"
                value={role}
                onChange={handleRoleValueChange}
              >
                {ROLES.map((userRole) => (
                  <MenuItem value={userRole}>{userRole}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            closeDialog();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={username === '' || role === ''}
          onClick={() => {
            // check to make sure the username is a valid stuller username
            isValidGemsUsername({ variables: { username } });
          }}
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface IGemsUsersMenuProps {
  handleAddUserDialog: (value: boolean) => void;
}

const GemsUsersMenu: FC<IGemsUsersMenuProps> = observer(({ handleAddUserDialog }) => (
  <Grid container spacing={2} marginBottom={4}>
    <Grid item>
      <Button
        variant="contained"
        onClick={() => {
          handleAddUserDialog(true);
        }}
      >
        Add New User
      </Button>
    </Grid>
  </Grid>
));

interface IGemsUsersResults {
  usersRolesCollection: Array<IListGemsUsersRolesResults>;
  loading: boolean;
}
const GemsUsersResults: FC<IGemsUsersResults> = observer(
  ({ usersRolesCollection, loading }) => {
    const [rows, setRows] = useState<GridRowModel[]>([]);
    const { authenticationStore } = useRootStore();
    const StyledGridOverlay = styled(GridOverlay)(() => ({
      flexDirection: 'column',
    }));
    // custom display when datagrid is empty
    const CustomNoRowsOverlay = () => (
      <StyledGridOverlay>
        <Box>
          <SearchIcon fontSize="large" color="secondary" />
        </Box>
        <Box sx={{ mt: 1 }}>
          <Typography variant="overline">
            Search for a valid username to load results.
          </Typography>
        </Box>
      </StyledGridOverlay>
    );
    const RenderRemoveUser = ({
      item,
      index,
    }: {
      item: { role: string; username: string };
      index: number;
    }) => {
      const [deleteProcessing, setDeleteProcessing] = useState<boolean>(false);
      const [removeUserWithRole] = useMutation(DELETE_USER_WITH_ROLE, {
        context: {
          headers: {
            Authorization: `Bearer ${authenticationStore.getAccessToken}`,
          },
        },
        onError: (err: ApolloError) => {
          toast.error(err.message, { theme: 'colored' });
        },
      });

      const handleOnRemoveButtonClick = () => {
        setDeleteProcessing(true);
        removeUserWithRole({
          variables: {
            role: item.role,
            username: item.username,
          },
        }).then((response) => {
          setDeleteProcessing(false);
          const rf = rows.filter((r) => r.id !== index);
          setRows(rf);
          console.log(rf, index);
        });
      };
      return (
        <>
          {deleteProcessing ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnRemoveButtonClick}>Delete</Button>
          )}
        </>
      );
    };
    // Custom Grid Render Component for Roles
    const RenderRoleSelect = ({ role, username }: { role: string; username: string }) => {
      const [currentRole, setCurrentRole] = useState<gemsUserGroups>(
        role as gemsUserGroups,
      );
      const [isLoading, setLoading] = useState<boolean>(false);
      const [createUserWithRoleMutation] = useMutation(CREATE_USER_WITH_ROLE, {
        context: {
          headers: {
            Authorization: `Bearer ${authenticationStore.getAccessToken}`,
          },
        },
        onError: (err: ApolloError) => {
          toast.error(err.message, { theme: 'colored' });
        },
      });
      const [deleteUserWithRoleMutation] = useMutation(DELETE_USER_WITH_ROLE, {
        context: {
          headers: {
            Authorization: `Bearer ${authenticationStore.getAccessToken}`,
          },
        },
        onError: (err: ApolloError) => {
          toast.error(err.message, { theme: 'colored' });
        },
      });
      // eslint-disable-next-line max-len
      const createUserWithRole = (user: string, newRole: gemsUserGroups) => createUserWithRoleMutation({ variables: { username: user, role: newRole } });
      // eslint-disable-next-line max-len
      const deleteUserWithRole = (user: string, oldRole: gemsUserGroups) => deleteUserWithRoleMutation({ variables: { username: user, role: oldRole } });
      const handleChangeUserRoleGroup = (
        user: string,
        oldRole: gemsUserGroups,
        newRole: gemsUserGroups,
      ) => {
        deleteUserWithRole(user, oldRole)
          .then((deleteResponse) => {
            console.log(deleteResponse);
            createUserWithRole(user, newRole).then((createResponse) => {
              const { username: uid, role: setRole } = createResponse.data.createUserRole;
              toast.success(`${uid} set to ${setRole}`);
              setCurrentRole(newRole);
              setLoading(false);
            });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      };
      const handleValueChange = (event: SelectChangeEvent) => {
        setLoading(true);
        const newRole = event.target.value as gemsUserGroups;
        handleChangeUserRoleGroup(username, currentRole, newRole);
      };
      return (
        <>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Select
              variant="standard"
              label="User Role"
              value={currentRole}
              onChange={handleValueChange}
            >
              {ROLES.map((userRole) => (
                <MenuItem value={userRole}>{userRole}</MenuItem>
              ))}
            </Select>
          )}
        </>
      );
    };
    // define column names and field ids for mapping row data
    const columns: GridColDef[] = [
      {
        field: 'username',
        headerName: 'Username',
        width: 200,
        sortable: false,
      },
      {
        field: 'role',
        headerName: 'Role Group',
        renderCell: (params) => RenderRoleSelect(params.value),
        width: 300,
        sortable: false,
      },
      {
        field: 'remove',
        headerName: 'Remove User',
        renderCell: (params) => RenderRemoveUser(params.value),
        sortable: false,
      },
    ];
    // Parses the query collection into the datagrid
    const createRowData = (usersRoleCollection: Array<IListGemsUsersRolesResults>) => {
      setRows(
        usersRoleCollection.map((item, index) => ({
          id: index,
          username: item.username,
          role: item,
          remove: { item, index },
        })),
      );
    };
    // Load data into Datagrid
    useEffect(() => {
      createRowData(usersRolesCollection);
    }, [usersRolesCollection, setRows]);
    return (
      <>
        <DataGrid
          components={{ NoRowsOverlay: CustomNoRowsOverlay }}
          loading={loading}
          rows={rows}
          columns={columns}
          autoHeight
          disableSelectionOnClick
          hideFooter
          disableColumnSelector
          disableColumnFilter
        />
      </>
    );
  },
);

const ManageGemsUsersView = observer(() => {
  const { authenticationStore, userStore } = useRootStore();
  const [usernameSearchValue, setUsernameSearchValue] = useState<string>('');
  const [userRolesCollection, setUserRolesCollection] = useState<
    Array<IListGemsUsersRolesResults>
  >([]);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false);
  const [getGemsUserRoles, { data, loading }] = useLazyQuery(GET_GEMS_USER_ROLES, {
    context: {
      headers: {
        Authorization: `Bearer ${authenticationStore.getAccessToken}`,
      },
    },
    fetchPolicy: 'network-only',
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' });
    },
  });

  const [createUserWithRoleMutation] = useMutation(CREATE_USER_WITH_ROLE, {
    context: {
      headers: {
        Authorization: `Bearer ${authenticationStore.getAccessToken}`,
      },
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' });
    },
  });

  // eslint-disable-next-line max-len
  const createUserWithRole = (user: string, newRole: gemsUserGroups) => createUserWithRoleMutation({ variables: { username: user, role: newRole } });

  const handleSearchValueChange = (e: any) => {
    setUsernameSearchValue(e.target.value);
    setUserRolesCollection([]);
  };

  const handleAddUserDialog = (isOpen: boolean) => {
    setAddUserDialogOpen(isOpen);
  };

  const handleAddUserWithRole = (username: string, role: string) => {
    createUserWithRole(username, role as gemsUserGroups)
      .then((response) => {
        toast.success(`Created user ${username}`);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const FetchAllUsers = () => {
    getGemsUserRoles({ variables: { input: {} } });
  };

  useEffect(() => {
    if (data) {
      const response = data.listUserRoles.items as Array<IListGemsUsersRolesResults>;
      console.log(response);
      setUserRolesCollection(
        response.filter(
          (item: IListGemsUsersRolesResults) => item.username !== userStore.getUsername,
        ),
      );
    }
    // loads all users automatically
    if (userRolesCollection.length === 0 && !loading && !data) {
      FetchAllUsers();
    }
  }, [data]);
  return (
    <>
      <AddGemsUserDialog
        dialogOpen={addUserDialogOpen}
        handleAddUserDialog={handleAddUserDialog}
        handleAddUserWithRole={handleAddUserWithRole}
        refreshAllUsers={FetchAllUsers}
      />
      <Grid container>
        <Grid item xs={12}>
          <GemsUsersMenu handleAddUserDialog={handleAddUserDialog} />
        </Grid>
        <Grid item xs={12}>
          <GemsUsersResults
            loading={loading}
            usersRolesCollection={userRolesCollection}
          />
        </Grid>
      </Grid>
    </>
  );
});

export default ManageGemsUsersView;
