import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import {
  FormControlLabel,
  Checkbox,
} from '@mui/material';

interface IFeatureFlagComponentProps {
  id: string;
  flagName: string;
  initialFlag: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const FeatureFlagComponent: FC<IFeatureFlagComponentProps> = observer(
  ({
    id, flagName, initialFlag, handleChange,
  }) => (
    <FormControlLabel
      control={<Checkbox id={id} checked={initialFlag} onChange={handleChange} />}
      label={flagName}
    />
  ),
);
