import { makeAutoObservable, runInAction } from 'mobx';
import dayjs from 'dayjs';
import axios from 'axios';
import { print } from 'graphql/language/printer';
import { RootStore } from './RootStore';
import { LOGIN_TOKEN } from '../graphql/queries/authenticationQueries';
import { IAuthenticateResponse } from '../graphql/types/authenticationTypes';

const localStorageKeys = {
  refreshToken: 'RTOKEN_V',
  accessToken: 'ATOKEN_V',
};
export default class AuthenticationStore {
  private rootStore: RootStore;

  // user's accessToken Value
  private accessToken: string;

  // user's refreshToken value, stored usually in localStorage
  // after a valid login.
  private refreshToken: string;

  // current user's authentication status
  private isAuthenticated: boolean;

  // the time that the stored access token will be expired
  private expirationTime: dayjs.Dayjs;

  // default constructor
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.isAuthenticated = false;
    this.accessToken = '';
    // always set expiration time to now, on startup
    this.expirationTime = dayjs();
    // check local storage for refreshToken, if its undefined set it to empty string
    this.refreshToken = localStorage.getItem(localStorageKeys.refreshToken) || '';
    makeAutoObservable(this);
  }

  // returns user's authentication status
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get getAccessToken(): string {
    runInAction(async () => {
      await this.refreshOrReturnAccessToken();
    });
    return this.accessToken;
  }

  get getRefreshToken(): string {
    return this.refreshToken;
  }

  // returns true if access token has expired.
  get isAccessTokenExpired(): boolean {
    return this.expirationTime.isBefore(dayjs());
  }

  // update the user's authentication status
  setAuthentication = (value: boolean): void => {
    this.isAuthenticated = value;
  };

  // stores access token in localStorage
  setAccessTokenValue = (value: string): void => {
    localStorage.setItem(localStorageKeys.accessToken, value);
    // getters must refrence local class members,
    // so we also store this as a local class member
    this.accessToken = value;
  };

  // stores refreshToken in localStorage
  setRefreshToken = (value: string): void => {
    localStorage.setItem(localStorageKeys.refreshToken, value);
    this.refreshToken = value;
  };

  setExpirationTime = (seconds: number): void => {
    // remove some time so we can refresh before the actual expiration time.
    const paddedTime = seconds - 240;
    // add x number of seconds to current time, this is the expiration time.
    this.expirationTime = dayjs().add(paddedTime, 'second');
  };

  attemptTokenLogin = (): void => {
    this.refreshOrReturnAccessToken();
  };

  logoutCurrentUser = (): void => {
    this.refreshToken = '';
    this.accessToken = '';
    this.setRefreshToken('');
    this.setAccessTokenValue('');
    this.isAuthenticated = false;
    this.expirationTime = dayjs();
  };

  // Sort of a hack to easily refresh the access token if
  // when requested it was expired..
  private refreshOrReturnAccessToken = async (): Promise<void> => {
    try {
      if (this.expirationTime.isBefore(dayjs())) {
        const request = await axios({
          url: `${process.env.REACT_APP_GATEWAY_URL}`,
          method: 'post',
          data: {
            query: print(LOGIN_TOKEN),
            variables: {
              refreshToken: this.refreshToken,
            },
          },
        });
        const response = request.data as IAuthenticateResponse;
        runInAction(() => {
          this.setAccessTokenValue(response.AccessToken);
          this.setExpirationTime(response.ExpiresIn);
        });
      }
    } catch (error) {
      console.log(error);
      this.setAccessTokenValue('');
      this.setExpirationTime(0);
    }
  };
}
