import { gql } from '@apollo/client';

export const IS_VALID_GEMS_USER = gql`
  query isValidGemsUser($username: String!) {
    isValidStullerUser(username: $username)
  }
`;

export const LIST_GEMS_USERS = gql`
  query {
    listGemsUsers(input: {}) {
      items {
        userID
        username
        shipTo
        billTo
        email
        firstName
        lastName
        lastLogin
        shipTos
      }
    }
  }
`;

export const CREATE_USER_WITH_ROLE = gql`
  mutation createUserWithRole($username: String!, $role: Role!) {
    createUserRole(username: $username, role: $role) {
      username
      role
    }
  }
`;

export const DELETE_USER_WITH_ROLE = gql`
  mutation deleteUserWithRole($username: String!, $role: Role!) {
    deleteUserRole(username: $username, role: $role) {
      userId
      role
    }
  }
`;

export const GET_GEMS_USER_SOFTWARE_ACCESS = gql`
  query getGemsUserSoftwareAccess($username: String!) {
    getGemsUser(username: $username) {
      userID
      softwareAccess {
        applicationID
        flagName
        flag
      }
    }
  }
`;

export const GET_GEMS_USER_ROLES = gql`
  query getGemsUserRoles($input: ListUserRolesInput!) {
    listUserRoles(input: $input) {
      items {
        userId
        username
        role
      }
    }
  }
`;
