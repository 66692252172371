import * as React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Divider, Typography } from '@mui/material';
import AppBarLayout from '../layouts/AppBarLayout';
import { useRootStore } from '../store/Context';

const DashboardView = observer(() => {
  document.title = 'Gems Admin Portal - Dashboard';
  const dashHeight = '720px';
  const { userStore } = useRootStore();
  return (
    <>
      <Grid container justifyContent="center" style={{ padding: '12px' }}>
        {/* DASH */}
        <Grid item xs={12}>
          <Typography variant="overline" fontSize={20}>
            Welcome,
            {` ${userStore.getUsername}`}
          </Typography>
          <Divider />
        </Grid>
      </Grid>
    </>
  );
});

export default DashboardView;
