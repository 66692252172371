import { gql } from '@apollo/client';

export const LOGIN_CREDENTIALS = gql`
  query login($username: String!, $password: String!) {
    loginWithCreds(username: $username, password: $password) {
      AccessToken
      ExpiresIn
      RefreshToken
    }
  }
`;

export const LOGIN_TOKEN = gql`
  query login($refreshToken: String!) {
    loginWithToken(refreshToken: $refreshToken) {
      AccessToken
      ExpiresIn
    }
  }
`;
