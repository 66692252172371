import * as React from 'react';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useHistory, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DiamondIcon from '@mui/icons-material/DiamondTwoTone';
import ArrowCircleRightIcon from '@mui/icons-material/DoubleArrow';
import {
  Drawer,
  Fade,
  IconButton,
  ListItemButton,
  ListSubheader,
  Slide,
  styled,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { MenuConfig } from '../data/MenuConfig';

const data = MenuConfig;

export interface IAppMenuProps {
  menuDrawerWidth: number;
}
const AppMenu: FC<IAppMenuProps> = observer(({ menuDrawerWidth }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <Drawer
      sx={{
        width: menuDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: menuDrawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        <ListItemButton
          component="a"
          href="/"
          sx={{ backgroundColor: 'rgba(0,0,0,0.2)', marginTop: -1 }}
        >
          <ListItemIcon sx={{ fontSize: 18 }}>
            <DiamondIcon fontSize="large" color="primary" />
          </ListItemIcon>
          <ListItemText
            sx={{ my: 0 }}
            primary="GEMS Admin Tool"
            primaryTypographyProps={{
              fontSize: 16,
              fontWeight: 'medium',
              letterSpacing: 0.5,
            }}
          />
        </ListItemButton>
        {data.map((element) => {
          switch (element.type) {
            case 'link':
              return (
                <ListItem
                  button
                  key={element.name}
                  onClick={() => history.push(`/${element.path}`)}
                  sx={{
                    backgroundColor:
                      location.pathname.substring(1) === element.path
                        ? 'rgba(2, 118, 170,0.5)'
                        : '',
                  }}
                >
                  <ListItemIcon>
                    {location.pathname.substring(1) === element.path ? (
                      <Slide direction="right" in>
                        <ArrowCircleRightIcon color="primary" />
                      </Slide>
                    ) : (
                      element.icon
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={element.name}
                    primaryTypographyProps={{
                      color: 'text.primary',
                      fontWeight: 'medium',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              );
            case 'divider':
              return (
                <ListSubheader key={element.name}>
                  <Typography color="secondary" variant="overline">
                    {element.name}
                  </Typography>
                </ListSubheader>
              );
            default:
              return <></>;
          }
        })}
      </List>
    </Drawer>
  );
});
// const Drawer = () => {
//   const history = useHistory();
//   const [state, setState] = React.useState({ burgerIcon: false });

//   // eslint-disable-next-line max-len
//   const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
//     if (
//       event
//         && event.type === 'keydown'
//         && ((event as React.KeyboardEvent).key === 'Tab'
//           || (event as React.KeyboardEvent).key === 'Shift')
//     ) {
//       return;
//     }

//     setState({ ...state, [anchor]: open });
//   };

//   const list = (anchor: Anchor) => (
//     <Box
//       sx={{ width: drawerWidth }}
//       role="presentation"
//       onClick={toggleDrawer(anchor, false)}
//       onKeyDown={toggleDrawer(anchor, false)}
//     >
//       <ListSubheader inset>{subheader}</ListSubheader>
//       <Divider />
//       <List>
//         {data.map((element) => {
//           switch (element.type) {
//             case 'link':
//               return (
//                 <ListItem
//                   button
//                   key={element.name}
//                   onClick={() => history.push(`/${element.path}`)}
//                 >
//                   <ListItemIcon>{element.icon}</ListItemIcon>
//                   <ListItemText primary={element.name} />
//                 </ListItem>
//               );
//             case 'divider':
//               return (
//                 <ListSubheader key={element.name}>
//                   <Typography color="secondary" variant="overline">
//                     {element.name}
//                   </Typography>
//                 </ListSubheader>
//               );
//             default:
//               return <></>;
//           }
//         })}
//       </List>
//     </Box>
//   );

//   return (
//     <div>
//       <React.Fragment key={anchorKey}>
//         <IconButton
//           size="large"
//           edge="start"
//           color="inherit"
//           aria-label="menu"
//           sx={{ mr: 2 }}
//           onClick={toggleDrawer(anchorKey, true)}
//         >
//           <MenuIcon />
//         </IconButton>
//         <SwipeableDrawer
//           anchor="left"
//           open={state[anchorKey]}
//           onClose={toggleDrawer(anchorKey, false)}
//           onOpen={toggleDrawer(anchorKey, true)}
//         >
//           {list(anchorKey)}
//         </SwipeableDrawer>
//       </React.Fragment>
//     </div>
//   );
// };

export default AppMenu;
