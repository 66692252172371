import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Button } from '@mui/material';

import { useRootStore } from '../store/Context';
import AppMenu from '../components/Drawer';

const AppBarLayout: React.FC = observer(({ children }) => {
  const appbarText = 'GEMS Admin Dashboard';
  const history = useHistory();
  const apolloClient = useApolloClient();
  const { authenticationStore } = useRootStore();
  // jank nasty
  const drawerMenuWidth = !authenticationStore.isUserAuthenticated ? 0 : 300;
  // Empties the token strings and routes to login page
  const handleLogout = (): void => {
    authenticationStore.logoutCurrentUser();
    apolloClient.cache.reset().then((value) => {
      console.log(value);
      history.push('/');
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <AppBar
        color="transparent"
        elevation={0}
        position="static"
        sx={{ mb: 2 }}
        style={{ display: authenticationStore.isUserAuthenticated ? 'block' : 'none' }}
      >
        <Toolbar>
          <AppMenu menuDrawerWidth={drawerMenuWidth} />
          <Typography variant="h6" component="div" sx={{ width: '100%' }} />
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleLogout();
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ paddingLeft: drawerMenuWidth }}>{children}</div>
    </Box>
  );
});

export default AppBarLayout;
