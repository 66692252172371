import { ReactElement } from 'react';
import {
  Switch, Route, useHistory, Router, Redirect,
} from 'react-router-dom';
import ForbiddenView from '../views/ForbiddenView';
import PublicRoute from './utils/PublicRoute';
import LoginView from '../views/LoginView';
import RoleRoute from './utils/RoleRoute';
import ProtectedRoute from './utils/ProtectedRoute';
import DashboardView from '../views/DashboardView';
import UsersByShipToView from '../views/ShipToView';
import GemsListView from '../views/GemsView';
import TrialsByUsernameView from '../views/TrialsView';
import AccountsView from '../views/AccountsView';
import EntitlementsView from '../views/EntitlementsView';
import PageWithSubTitleLayout from '../layouts/PageWithSubTitleLayout';
import ManageAccountEntitlementsView from '../views/ManageAccountEntitlementsView';
import UserSoftwareAccess from '../views/UserSoftwareAccess';
import ManageGemsUsersView from '../views/ManageGemsUsersView';
import { MenuConfig } from '../data/MenuConfig';
import UserFeatureFlags from '../views/ManageUserFeatureFlags';

const Routes = (): ReactElement => {
  const history = useHistory();

  return (
    <Switch>
      <Router history={history}>
        <Route exact path="/">
          <PublicRoute>
            <Redirect to="/login" />
          </PublicRoute>
        </Route>
        <Route exact path="/login">
          <PublicRoute>
            <LoginView />
          </PublicRoute>
        </Route>
        <Route exact path="/dashboard">
          <ProtectedRoute>
            <DashboardView />
          </ProtectedRoute>
        </Route>
        <Route path="/dashboard/account/entitlements">
          <ProtectedRoute>
            <RoleRoute requiredRole={['SUPPORT', 'ADMIN', 'SUPPORTREADONLY']}>
              <PageWithSubTitleLayout
                subTitle="Manage Account Entitlements"
                marginTop={2}
                direction="column"
              >
                <ManageAccountEntitlementsView writeAccessRole={['SUPPORT', 'ADMIN']} />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        </Route>
        <Route exact path="/dashboard/user/trials">
          <ProtectedRoute>
            <RoleRoute requiredRole={['SUPPORT', 'ADMIN', 'SUPPORTREADONLY']}>
              <PageWithSubTitleLayout
                subTitle="Manage User Trials"
                marginTop={2}
                direction="column"
              >
                <TrialsByUsernameView writeAccessRole={['SUPPORT', 'ADMIN']} />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        </Route>
        <Route exact path="/dashboard/shiptoreport">
          <ProtectedRoute>
            <RoleRoute requiredRole={['SUPPORT', 'SUPPORTREADONLY', 'DEV', 'OPS', 'ADMIN']}>
              <UsersByShipToView />
            </RoleRoute>
          </ProtectedRoute>
        </Route>
        <Route exact path="/dashboard/accountsreport">
          <ProtectedRoute>
            <RoleRoute requiredRole={['SUPPORT', 'SUPPORTREADONLY', 'DEV', 'OPS', 'ADMIN']}>
              <AccountsView />
            </RoleRoute>
          </ProtectedRoute>
        </Route>
        <Route exact path="/dashboard/gemsreport">
          <ProtectedRoute>
            <RoleRoute
              requiredRole={['SUPPORT', 'SUPPORTREADONLY', 'DEV', 'OPS', 'ADMIN']}
            >
              <GemsListView />
            </RoleRoute>
          </ProtectedRoute>
        </Route>
        <Route exact path="/dashboard/entitlements">
          <ProtectedRoute>
            <RoleRoute requiredRole={['SUPPORT', 'SUPPORTREADONLY', 'ADMIN']}>
              <EntitlementsView />
            </RoleRoute>
          </ProtectedRoute>
        </Route>
        <Route exact path="/dashboard/user/softwareaccess">
          <ProtectedRoute>
            <RoleRoute requiredRole={['DEV', 'ADMIN']}>
              <PageWithSubTitleLayout
                subTitle="Manage User Feature Access"
                marginTop={2}
                direction="column"
              >
                <UserSoftwareAccess />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        </Route>
        <Route exact path="/dashboard/user/featureflags">
          <ProtectedRoute>
            <RoleRoute requiredRole={['DEV', 'ADMIN']}>
              <PageWithSubTitleLayout
                subTitle="Manage User Feature Flags"
                marginTop={2}
                direction="column"
              >
                <UserFeatureFlags />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        </Route>
        <Route exact path="/dashboard/admin/users">
          <ProtectedRoute>
            <RoleRoute requiredRole={['ADMIN']}>
              <PageWithSubTitleLayout
                subTitle="Manage GEMS Users"
                marginTop={2}
                direction="column"
              >
                <ManageGemsUsersView />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        </Route>
        <Route path="/forbidden">
          <ForbiddenView />
        </Route>
      </Router>
    </Switch>
  );
};

export default Routes;
