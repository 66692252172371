import { gql } from '@apollo/client';

export const LIST_STULLER_USERS_BY_SHIP_TO = gql`
  query verify($shipTo: String!){
    listStullerUsersByShipTo(shipTo: $shipTo) {
      loginId,
      email,
    }
  }
`;
