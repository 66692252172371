import { createContext, ReactElement, useContext } from 'react';
import { RootStore } from './RootStore';

const StoreContext = createContext<RootStore | undefined>(undefined);

// rootStore instance
let rootStoreInstance: RootStore;

// makes rootStore a singleton
const singletonRootStore = (): RootStore => {
  if (!rootStoreInstance) {
    rootStoreInstance = new RootStore();
  }
  return rootStoreInstance;
};

const StoreProvider: React.FC = ({ children }): ReactElement => (
  <StoreContext.Provider value={singletonRootStore()}>
    {children}
  </StoreContext.Provider>
);

// context hook for fetching rootStore instance from react context
const useRootStore = (): RootStore => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error(
      'useRootStore must be used within the RootStoreProvider context.',
    );
  }
  return context;
};

export { StoreProvider, useRootStore };
