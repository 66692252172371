import { makeAutoObservable } from 'mobx';
import crypto from 'crypto';
import { RootStore } from './RootStore';

const localStorageKeys = {
  username: 'USERNAME_V',
};
export default class UserStore {
  private rootStore: RootStore;

  private username: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.username = localStorage.getItem(localStorageKeys.username) || '';
    makeAutoObservable(this);
  }

  setUsername = (username: string): void => {
    this.username = username;
  };

  // on successful login, store the username
  storeUsernameInLocalStorage = (): void => {
    localStorage.setItem(localStorageKeys.username, this.username);
  };

  get getUsername(): string {
    return this.username;
  }

  get getUsernameHash(): string {
    return crypto.createHash('sha256').update(this.username).digest('hex');
  }
}
