import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-shadow
// eslint-disable-next-line max-len
const PublicRoute: React.FC = ({ children }) => (
  <>
    {children}
  </>
);

export default PublicRoute;
