import * as React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import AppBarLayout from '../layouts/AppBarLayout';

const EntitlementsView = observer(() => {
  document.title = 'Gems Admin Portal - Dashboard';
  const dashHeight = '720px';

  return (
    <>
      <Grid container justifyContent="center">
        {/* DASH */}
        <Grid item xs={10} style={{ height: dashHeight }} />
      </Grid>
    </>
  );
});

export default EntitlementsView;
