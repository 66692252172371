// CONVERTER: APP ID CONVERSION
import dayjs from 'dayjs';

export const matrixGold3 = '596AB1E7-1585-4E4C-8383-9FB47A1821BD';
export const matrixGold2 = 'be1f6ce0-a17b-11e8-98b7-310b8b71d1dd';
export const counterSketch = 'BE39B0AE-3649-4899-807E-51C73F70FEE7';
export const counterSketchStreaming = 'c3ee2bf3-13b0-47ea-b64d-fe05fdd484fb';
export const matrixGoldMembership = 'F2252DA0-CB8D-11EC-9D64-0242AC120002';
export const counterSketchMembership = '518E8E08-2A90-4E8C-AF10-8E8B97D7376F';

// CONVERTER: UNIX TIME STAMP TO DATE STRING
export const convertTimeStamp = (unixTimeStamp: number) => {
  if (unixTimeStamp === 0) return 'None';
  return dayjs.unix(unixTimeStamp).format('MM/DD/YY');
};
const buyingGroupPrefixes = [
  '46384', // RJO
  '136368', // Canadian Jewelery Group
  '41915', // Riddles
  '41525', // Sterling (Jareds)
];
export const isAccount = (shipToOrBillTo: string) => RegExp('[\\d]{1,6}-[\\d]{6,}$').test(shipToOrBillTo);
// eslint-disable-next-line max-len
export const isBuyingGroupBillto = (shipToOrBillTo: string) => buyingGroupPrefixes.findIndex((value) => `${value}-000000` === shipToOrBillTo) !== -1;

export const convertShipToBillTo = (shipTo: string) => {
  const splitShipTo = shipTo.split('-');
  // buying group exception
  if (buyingGroupPrefixes.findIndex((value) => value === splitShipTo[0]) !== -1) {
    return splitShipTo.join('');
  }
  splitShipTo[1] = '000000';
  return splitShipTo.join('-');
};

export const applicationCollection = [
  matrixGold3,
  matrixGold2,
  counterSketch,
  counterSketchStreaming,
  matrixGoldMembership,
  counterSketchMembership,
];

interface appRecordDescription {
  isSubscription: boolean,
  sortIndex: number,
  title: string,
  type?: string,
}

export const applicationCollectionData: Record<string, appRecordDescription> = {
  [matrixGold3]: {
    isSubscription: false,
    sortIndex: 0,
    title: 'Matrixgold 3.X',
  },
  [matrixGold2]: {
    isSubscription: false,
    sortIndex: 1,
    title: 'Matrixgold 2.X',
  },
  [counterSketch]: {
    isSubscription: false,
    title: 'Countersketch',
    sortIndex: 3,
  },
  [counterSketchStreaming]: {
    isSubscription: true,
    sortIndex: 2,
    title: 'Countersketch Streaming',
    type: 'month',
  },
  [matrixGoldMembership]: {
    isSubscription: true,
    sortIndex: 4,
    title: 'MG Membership',
    type: 'year',
  },
  [counterSketchMembership]: {
    isSubscription: true,
    sortIndex: 5,
    title: 'CS Membership',
    type: 'year',
  },
};

export const convertAppId = (appId: string) => {
  if (appId === '') return 'INVALID APP ID';

  if (appId in applicationCollectionData) {
    return applicationCollectionData[appId].title;
  }
  if (appId === applicationCollectionData[matrixGold3].title) return matrixGold3;
  if (appId === applicationCollectionData[matrixGold2].title) return matrixGold2;
  if (appId === applicationCollectionData[counterSketch].title) return counterSketch;
  if (appId === applicationCollectionData[counterSketchStreaming].title) return counterSketchStreaming;
  if (appId === applicationCollectionData[matrixGoldMembership].title) return matrixGoldMembership;
  if (appId === applicationCollectionData[counterSketchMembership].title) return counterSketchMembership;
  if (appId === 'INVALID APP ID') return '';

  return 'INVALID APP ID';
};
