import { gql } from '@apollo/client';

export const LIST_USER_ROLES = gql`
  query verify($limit: Int){
    listUserRoles(input: {limit: $limit}) {
      items {
        userId
        role
      }
      nextKey
    }
  }
`;

export const GET_USER_ROLES = gql`
  query verify($userId: String!){
    listUserRoles(input: {userId: $userId}) {
      items {
        role
      }
    }
  }
`;

export const CREATE_USER_ROLE = gql`
  mutation verify($userId: String!, $role: Role!){
    createUserRole(input: {userId: $userId, role: $role}) {
      userId,
      role,
    }
  }
`;

export const DELETE_USER_ROLE = gql`
  mutation verify($userId: String!, $role: Role!){
    deleteUserRole(input: {userId: $userId, role: $role}) {
      userId,
      role,
    }
  }
`;

// appId:
// be1f6ce0-a17b-11e8-98b7-310b8b71d1dd
// c3ee2bf3-13b0-47ea-b64d-fe05fdd484fb
