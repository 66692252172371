import { useLazyQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LOGIN_TOKEN } from './graphql/queries/authenticationQueries';
import { IAuthenticateResponse } from './graphql/types/authenticationTypes';
import AppBarLayout from './layouts/AppBarLayout';
import FullWidthCenteredLayout from './layouts/FullWidthCenteredLayout';
import Routes from './routes';
import { useRootStore } from './store/Context';

const App = observer(() => {
  const { authenticationStore } = useRootStore();
  const history = useHistory();
  const [loginUsingRefreshToken, {
    called, loading, data, error,
  }] = useLazyQuery(
    LOGIN_TOKEN,
    {
      variables: {
        refreshToken: authenticationStore.getRefreshToken,
      },
    },
  );

  useEffect(() => {
    // if a refreshToken was stored in localStorage
    if (authenticationStore.getRefreshToken !== '' && !called) {
      loginUsingRefreshToken();
    }
    if (called) {
      if (data) {
        const loginData = data.loginWithToken as IAuthenticateResponse;
        authenticationStore.setAccessTokenValue(loginData.AccessToken);
        authenticationStore.setExpirationTime(loginData.ExpiresIn);
        authenticationStore.setAuthentication(true);
        history.push('/dashboard/user/trials');
      }
      if (error) {
        authenticationStore.setAuthentication(false);
        console.log(error);
      }
    }
  }, [
    authenticationStore,
    data,
    error,
    loading,
    called,
    loginUsingRefreshToken,
    history,
  ]);
  return (
    <>
      {loading ? (
        <FullWidthCenteredLayout marginTop={50} direction="column">
          <CircularProgress />
        </FullWidthCenteredLayout>
      ) : (
        <AppBarLayout>
          <Routes />
        </AppBarLayout>
      )}
    </>
  );
});

export default App;
