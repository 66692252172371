import { FC, Fragment } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { observer } from 'mobx-react-lite';

interface IAccordionSectionProps {
  sectionName: string;
  content: Array<any>;
}
export const AccordionSection: FC<IAccordionSectionProps> = observer(
  ({ sectionName, content }) => (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="overline">{sectionName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {content.map((node, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>{node}</Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  ),
);
