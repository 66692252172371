import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/system';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import { StoreProvider } from './store/Context';
import 'react-toastify/dist/ReactToastify.css';

import { themeOptions } from './theme';
// Apollo Gql Client
const client = new ApolloClient({
  uri: process.env.REACT_APP_GATEWAY_URL,
  cache: new InMemoryCache(),
});
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeOptions}>
      <StoreProvider>
        <ApolloProvider client={client}>
          <ToastContainer />
          <Router>
            <App />
            <CssBaseline />
          </Router>
        </ApolloProvider>
      </StoreProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
