import { ApolloError, useMutation } from '@apollo/client';
import {
  Grid,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Paper,
  Typography,
  LinearProgress,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { convertAppId } from '../../data/Helpers';
import { SET_USER_SOFTWARE_ACCESS } from '../../graphql/queries/accountQueries';
import { useRootStore } from '../../store/Context';
import { ISoftwareAccessResult } from '../../views/UserSoftwareAccess';

export interface ISoftwareAccessComponentProps {
  userID: string;
  username: string;
  applicationID: string;
  gemsUserData: Array<ISoftwareAccessResult>;
}
const SoftwareAccessComponent: FC<ISoftwareAccessComponentProps> = observer(
  ({
    userID, username, applicationID, gemsUserData,
  }) => {
    const [devFlag, setDevFlag] = useState(false);
    const [rcFlag, setRcFlag] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { authenticationStore } = useRootStore();
    const [setUserSoftwareAccess] = useMutation(SET_USER_SOFTWARE_ACCESS, {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
      fetchPolicy: 'network-only',
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' });
      },
    });

    const filterAppFlag = (flagName: string): ISoftwareAccessResult | undefined => gemsUserData.find(
      (d) => d.applicationID === applicationID && d.flagName === flagName,
    );

    const handleFlagMutation = (flagValue: boolean, flagName: string) => {
      setLoading(true);
      setUserSoftwareAccess({
        variables: {
          userID,
          applicationID,
          flagName,
          flag: flagValue,
        },
      })
        .then((data) => {
          console.log(JSON.stringify(data));
          toast.success(
            `${username}: ${convertAppId(applicationID)} ${flagName} set to ${flagValue}`,
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.id === 'dev') {
        setDevFlag(event.target.checked);
        handleFlagMutation(event.target.checked, 'DEV');
      }
      if (event.target.id === 'rc') {
        setRcFlag(event.target.checked);
        handleFlagMutation(event.target.checked, 'RC');
      }
    };

    useEffect(() => {
      const devSoftwareAccess = filterAppFlag('DEV');
      const rcSoftwareAccess = filterAppFlag('RC');
      if (devSoftwareAccess !== undefined) {
        setDevFlag(devSoftwareAccess.flag);
      }
      if (rcSoftwareAccess !== undefined) {
        setRcFlag(rcSoftwareAccess.flag);
      }
    }, [gemsUserData, setRcFlag, setDevFlag]);

    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper sx={{ padding: 2, backgroundColor: '#212121' }}>
            <FormGroup>
              <FormLabel>
                <Typography variant="button" color="primary">
                  Software Release Access
                </Typography>
              </FormLabel>
              <FormControlLabel
                control={<Checkbox id="dev" checked={devFlag} onChange={handleChange} />}
                label="Developer"
              />
              <FormControlLabel
                control={<Checkbox id="rc" checked={rcFlag} onChange={handleChange} />}
                label="Release Candidate"
              />
              <FormHelperText>Allows a user to view/toggle these options.</FormHelperText>
              <LinearProgress sx={{ display: loading ? 'block' : 'none' }} />
            </FormGroup>
          </Paper>
        </Grid>
      </Grid>
    );
  },
);

export default SoftwareAccessComponent;
