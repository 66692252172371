import {
  Stack, Paper, InputBase, IconButton,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';

export interface ISearchBarComponentProps {
  handleSearchChange: (e: any) => void;
  handleSearch: (e: any) => void;
  searchTextValue: string;
  placeHolderSearchText: string;
  elementId?: string;
}
const SearchBarComponent: FC<ISearchBarComponentProps> = observer(
  ({
    handleSearch,
    handleSearchChange,
    placeHolderSearchText,
    searchTextValue,
    elementId,
  }) => (
    <Paper
      component="form"
      noValidate
      autoComplete="off"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
    >
      <InputBase
        type="search"
        id={elementId ?? 'searchBar'}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeHolderSearchText}
        size="small"
        onChange={handleSearchChange}
        value={searchTextValue}
      />
      <IconButton
        type="submit"
        color="primary"
        onClick={handleSearch}
        disabled={searchTextValue === ''}
      >
        <SearchIcon color="secondary" />
      </IconButton>
    </Paper>
  ),
);

export default SearchBarComponent;
