import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
} from '@mui/x-data-grid';
import {
  Box,
  Grid,
  Button,
  Stack,
  ClickAwayListener,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  styled,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  LinearProgress,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import TimeIcon from '@mui/icons-material/Timer';
import DeleteIcon from '@mui/icons-material/Delete';
import PlusIcon from '@mui/icons-material/Add';
import SearchUserIcon from '@mui/icons-material/SearchTwoTone';
import {
  CREATE_USER_TRIAL,
  LIST_USER_TRIALS_BY_USERNAME,
  REMOVE_USER_TRIAL,
  EXTEND_USER_TRIAL,
  LIST_STULLER_USERS_BY_SHIP_TO,
} from '../graphql/queries/trialQueries';
import { useRootStore } from '../store/Context';
import {
  applicationCollectionData,
  convertAppId,
  convertTimeStamp,
  counterSketch,
  counterSketchStreaming,
  matrixGold3,
} from '../data/Helpers';
import SearchBarComponent from '../components/SearchBarComponent';

const shipToUserSelectStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
interface IByShipToUserSelectModalProps {
  handleUsernameSearch: () => void;
  setUsernameSearchTerm: (username: string) => void;
}
const ByShipToUserSelectModal: React.FC<IByShipToUserSelectModalProps> = ({
  handleUsernameSearch,
  setUsernameSearchTerm,
}) => {
  const { authenticationStore } = useRootStore();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    handleUsernameSearch();
    setOpen(false);
  };
  const [usernames, setUsernames] = useState<string[]>([]);
  const [username, setUsername] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const handleChange = (event: SelectChangeEvent) => {
    setUsername(event.target.value as string);
    setUsernameSearchTerm(event.target.value as string);
  };
  const [listStullerUsersByShipTo, { loading, data, error }] = useLazyQuery(
    LIST_STULLER_USERS_BY_SHIP_TO,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' });
      },
      fetchPolicy: 'network-only',
    },
  );
  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value as string);
  };
  const regex = RegExp('[\\d]{1,6}-[\\d]{6,}$');
  const handleSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    const formattedSearch = searchTerm.trim().toLowerCase();
    console.log(`searchTerm: ${formattedSearch}`);
    if (regex.test(formattedSearch)) {
      listStullerUsersByShipTo({
        variables: {
          shipTo: formattedSearch,
        },
      });
    } else {
      toast.error('Invalid Ship To format provided', { theme: 'colored' });
      setSearchTerm('');
      setUsernames([]);
    }
  };
  useEffect(() => {
    if (error) {
      toast.error(error.message, { theme: 'colored' });
    }
    if (data) {
      setUsernames(
        data.listStullerUsersByShipTo.map((item: any) => item.loginId),
      );
    }
  }, [error, data]);
  return (
    <>
      <Button onClick={handleOpen}>By Ship-To</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={shipToUserSelectStyle}>
          <SearchBarComponent
            handleSearchChange={handleSearchChange}
            handleSearch={handleSearch}
            placeHolderSearchText="Search for Ship-To Number (e.g. 141833-000001)"
            searchTextValue={searchTerm}
            elementId="searchUserByShipTo"
          />
          {
            loading
              ? <LinearProgress />
              : (
                <FormControl fullWidth>
                  <InputLabel id="username-label">Username</InputLabel>

                  <Select
                    labelId="username-label"
                    id="username"
                    value={username}
                    label="Username"
                    onChange={handleChange}
                  >
                    {
                      usernames.map((value) => <MenuItem value={value}>{value}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              )
          }
        </Box>
      </Modal>
    </>
  );
};

interface IExtendTrialDialogProps {
  extendDialogOpen: boolean;
  handleExtendDialogClose: () => void;
  handleExtendTrial: (username: string, applicationId: string, days: number) => void;
  userName: string;
  trialApplication: string;
}

const ExtendTrialDialog: React.FC<IExtendTrialDialogProps> = ({
  extendDialogOpen,
  handleExtendDialogClose,
  handleExtendTrial,
  userName,
  trialApplication,
}) => {
  const defaultExtensionTimeInDays = 14;
  const [extensionDays, setExtensionDays] = useState<number>(defaultExtensionTimeInDays);
  return (
    <Dialog
      open={extendDialogOpen}
      onClose={handleExtendDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Trial ${trialApplication} Extension`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {'How many days do you want to extend '}
          <Typography variant="overline" color="secondary">
            {userName}
          </Typography>
          {"'s trial for "}
          <Typography variant="overline" color="secondary">
            {trialApplication}
          </Typography>
        </DialogContentText>
        <Grid>
          <Grid item justifyContent="center" alignItems="center" alignContent="center">
            <TextField
              value={extensionDays}
              id="input-days"
              label="Days"
              type="number"
              onChange={(e) => {
                setExtensionDays(Number(e.target.value));
              }}
              fullWidth
              variant="filled"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setExtensionDays(defaultExtensionTimeInDays);
            handleExtendDialogClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            handleExtendTrial(userName, convertAppId(trialApplication), extensionDays);
            setExtensionDays(defaultExtensionTimeInDays);
            handleExtendDialogClose();
          }}
          autoFocus
        >
          Extend
        </Button>
      </DialogActions>
    </Dialog>
  );
};
interface IDeleteConfirmDialogProps {
  deleteConfirmationDialogOpen: boolean;
  handleDeleteDialogClose: () => void;
  handleRemoveTrial: (appId: string) => void;
  userName: string;
  trialApplication: string;
}
const DeleteConfirmationDialog: React.FC<IDeleteConfirmDialogProps> = ({
  deleteConfirmationDialogOpen,
  handleDeleteDialogClose,
  handleRemoveTrial,
  userName,
  trialApplication,
}) => (
  <Dialog
    open={deleteConfirmationDialogOpen}
    onClose={handleDeleteDialogClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Trial Removal Confirmation</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {`Are you sure you want to remove the trial for ${trialApplication} from ${userName}?`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="secondary" onClick={handleDeleteDialogClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => {
          handleRemoveTrial(trialApplication);
          handleDeleteDialogClose();
        }}
        autoFocus
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);
interface ITrialsMenuProps {
  handleAddTrial: (appId: string) => void;
  handleRemoveTrial: (selected: any) => void;
  handleChange: (event: any) => void;
  handleSearch: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  ownedUserTrials: Array<string>;
  selectedRow: any;
  readOnlyAccess: boolean;
  userSelected: boolean;
  searchTerm: string;
  isLoading: boolean;
}
const TrialsMenuComponent: React.FC<ITrialsMenuProps> = observer(
  ({
    handleAddTrial,
    handleChange,
    handleSearch,
    searchTerm,
    readOnlyAccess,
    isLoading,
    userSelected,
    ownedUserTrials,
  }) => {
    // eslint-disable-next-line max-len
    const buttonsDisabled = (appName: string) => readOnlyAccess || (!isLoading && !userSelected) || ownedUserTrials.indexOf(appName) !== -1;

    return (
      <div style={{ margin: '10px' }}>
        <Stack spacing={3} direction="row" alignItems="center">
          <SearchBarComponent
            handleSearchChange={handleChange}
            handleSearch={handleSearch}
            placeHolderSearchText="Username"
            searchTextValue={searchTerm}
            elementId="searchUserTrials"
          />

          <Divider orientation="vertical" variant="inset" flexItem />
          <Typography variant="overline">Software Trials:</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<PlusIcon color="secondary" />}
            onClick={() => handleAddTrial(matrixGold3)}
            disabled={buttonsDisabled('matrixgold')}
          >
            {applicationCollectionData[matrixGold3].title}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<PlusIcon color="secondary" />}
            onClick={() => handleAddTrial(counterSketch)}
            disabled={buttonsDisabled('countersketch')}
          >
            {applicationCollectionData[counterSketch].title}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<PlusIcon color="secondary" />}
            onClick={() => handleAddTrial(counterSketchStreaming)}
            disabled={buttonsDisabled('countersketchstreaming')}
          >
            {applicationCollectionData[counterSketchStreaming].title}
          </Button>
        </Stack>
      </div>
    );
  },
);

// Type userTrial for row data.
type userTrial = {
  appId: string;
  trialCreationDate: number;
  trialExpirationDate: number;
  lastLogin: number;
  trialExtensions: number;
};
interface ITrialsViewProps {
  writeAccessRole: string[];
}
const TrialsView: React.FC<ITrialsViewProps> = observer(({ writeAccessRole }) => {
  document.title = 'Gems Admin Portal - Trials';
  const dashHeight = '720px';
  const { authenticationStore, rolesStore } = useRootStore();
  const [readOnlyAccess, setReadOnlyAccess] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [userSelected, setUserSelected] = useState<boolean>(false);
  const regex = /([\w-]+)/;
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
  const [applicationDeletionName, setApplicationDeletionName] = useState<string>('');
  const [extendDialogOpen, setExtendDialogOpen] = useState<boolean>(false);
  const [userOwnedTrials, setUserOwnedTrials] = useState<Array<string>>([]);

  const handleExtendDialogOpen = () => {
    setExtendDialogOpen(true);
  };
  const handleExtendDialogClose = () => {
    setExtendDialogOpen(false);
  };
  const handleDeleteDialogClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteConfirmationOpen(true);
  };

  // QUERY: userTrials data.
  const [listUserTrials, { loading, data, error }] = useLazyQuery(
    LIST_USER_TRIALS_BY_USERNAME,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authenticationStore.getAccessToken}`,
        },
      },
      onError: (err: ApolloError) => {
        toast.error(err.message, { theme: 'colored' });
      },
      fetchPolicy: 'network-only',
    },
  );

  // MUTATION: remove a trial.
  const [removeTrial] = useMutation(REMOVE_USER_TRIAL, {
    context: {
      headers: {
        Authorization: `Bearer ${authenticationStore.getAccessToken}`,
      },
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' });
    },
  });

  // MUTATION: add a trial.
  const [addTrial] = useMutation(CREATE_USER_TRIAL, {
    context: {
      headers: {
        Authorization: `Bearer ${authenticationStore.getAccessToken}`,
      },
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' });
    },
  });

  // MUTATION: extend trial
  const [extendTrial] = useMutation(EXTEND_USER_TRIAL, {
    context: {
      headers: {
        Authorization: `Bearer ${authenticationStore.getAccessToken}`,
      },
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' });
    },
  });

  const renderRemoveTrialButton = (params: GridRenderCellParams<any>) => (
    <strong>
      <Button
        variant="outlined"
        color="error"
        fullWidth
        startIcon={<DeleteIcon />}
        onClick={(event: any) => {
          event?.stopPropagation();
          setApplicationDeletionName(params.row.col1);
          handleDeleteDialogOpen();
          console.log(params);
          console.log(params.value);
        }}
        disabled={readOnlyAccess}
      >
        Remove
      </Button>
    </strong>
  );

  const renderAddTrialTimeButton = (params: GridRenderCellParams<any>) => (
    <strong>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        startIcon={<TimeIcon />}
        onClick={(event: any) => {
          event?.stopPropagation();
          setApplicationDeletionName(params.row.col1);
          handleExtendDialogOpen();
        }}
        disabled={readOnlyAccess}
      >
        Add Time
      </Button>
    </strong>
  );

  // GRID COLUMNS
  const columns: GridColDef[] = [
    {
      field: 'col1',
      headerName: 'Trials',
      width: 200,
      sortable: false,
    },
    { field: 'col2', headerName: 'Created', sortable: false },
    { field: 'col3', headerName: 'Expires', sortable: false },
    { field: 'col4', headerName: 'Last Login', sortable: false },
    { field: 'col5', headerName: 'Extensions', sortable: false },
    {
      field: 'col6',
      headerName: 'Add Time',
      renderCell: renderAddTrialTimeButton,
      width: 150,
      sortable: false,
    },
    {
      field: 'col7',
      headerName: 'Remove Trial',
      renderCell: renderRemoveTrialButton,
      width: 150,
      sortable: false,
    },
  ];

  // // GRID ROWS (testing)
  const handleDataGrid = () => {
    console.log('setting rows:');
    listUserTrials({
      variables: {
        username: searchTerm,
      },
    });
    console.log('setting rows: rows set');
  };

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    const formattedSearch = searchTerm.trim().toLowerCase();
    console.log(`searchTerm: ${formattedSearch}`);
    if (regex.test(formattedSearch)) {
      listUserTrials({
        variables: {
          username: formattedSearch,
        },
      });
      setUserSelected(true);
    } else {
      setSearchTerm('');
      setUserSelected(false);
      setRows([]);
    }
  };

  const handleShipToUserSearch = (): void => {
    const formattedSearch = searchTerm.trim().toLowerCase();
    console.log(`searchTerm: ${formattedSearch}`);
    if (regex.test(formattedSearch)) {
      listUserTrials({
        variables: {
          username: formattedSearch,
        },
      });
      setUserSelected(true);
    } else {
      setSearchTerm('');
      setUserSelected(false);
      setRows([]);
    }
  };

  // HANDLER: HANDLE CHANGE
  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
    setUserSelected(false);
    setRows([]);
  };

  const refreshDataGrid = async () => {
    try {
      setIsLoading(true);
      await listUserTrials({
        variables: {
          username: searchTerm,
        },
      });
      handleDataGrid();
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  // HANDLER: REMOVE TRIAL
  const handleRemoveTrial = async (appName: string) => {
    await removeTrial({
      variables: {
        username: searchTerm,
        appId: convertAppId(appName),
      },
    });
    refreshDataGrid();
  };

  // HANLDER: ADD TRIAL
  const handleAddTrial = async (appId: string) => {
    await addTrial({
      variables: {
        username: searchTerm,
        applicationId: appId,
        trialDays: 14,
      },
    });
    refreshDataGrid();
    console.log(`handleAddTrial: \n ${appId} trial added`);
  };

  // Handle Extend Trial
  const handleExtendTrial = async (
    username: string,
    applicationId: string,
    days: number,
  ) => {
    try {
      await extendTrial({
        variables: {
          username,
          applicationId,
          trialDays: days,
        },
      });
      refreshDataGrid();
    } catch (e) {
      console.log(e);
    }
  };

  // USE EFFECT
  useEffect(() => {
    // check user roles to see if they have read only access
    const roles = rolesStore.getRoles;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < roles.length; i++) {
      const userRole = roles[i];
      console.log(`userRole: ${userRole}`);
      const found = writeAccessRole.findIndex((element) => element === userRole);
      if (found > -1) {
        console.log('write access found');
        setReadOnlyAccess(false);
        break;
      }
      console.log('write access not found');
    }
    if (loading) {
      setIsLoading(true);
    }
    if (error) {
      console.log(`useEffect: has error: ${error.message}`);
      setIsLoading(false);
    }
    if (data) {
      // sort the list so names appear in similar places on add / removal
      // the map, gets rid of the readonly collection issues
      const sortedDataList = data.listUserTrials.items
        .map((i: userTrial) => i)
        .sort((a: userTrial, b: userTrial) => a.appId.localeCompare(b.appId));

      setUserOwnedTrials(
        sortedDataList.map((e: userTrial) => convertAppId(e.appId).toLowerCase()),
      );
      setRows(
        sortedDataList.map((element: userTrial, index: number) => ({
          id: index + 1,
          col1: convertAppId(element.appId),
          col2: convertTimeStamp(element.trialCreationDate),
          col3: convertTimeStamp(element.trialExpirationDate),
          col4: convertTimeStamp(element.lastLogin),
          col5: element.trialExtensions,
          col6: element.appId,
          col7: element.appId,
        })),
      );
      console.log('useEffect: has data: setting rows: rows set');
      setIsLoading(false);
    }
  }, [data, loading, error, rolesStore, writeAccessRole]);

  const StyledGridOverlay = styled(GridOverlay)(() => ({
    flexDirection: 'column',
  }));
  const CustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <Box>
        <SearchUserIcon fontSize="large" color="secondary" />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="overline">
          Search for a valid username to load results.
        </Typography>
      </Box>
    </StyledGridOverlay>
  );

  return (
    <>
      <Grid container justifyContent="center">
        <DeleteConfirmationDialog
          handleDeleteDialogClose={handleDeleteDialogClose}
          handleRemoveTrial={handleRemoveTrial}
          deleteConfirmationDialogOpen={deleteConfirmationOpen}
          userName={searchTerm}
          trialApplication={applicationDeletionName}
        />
        <ExtendTrialDialog
          handleExtendDialogClose={handleExtendDialogClose}
          extendDialogOpen={extendDialogOpen}
          handleExtendTrial={handleExtendTrial}
          userName={searchTerm}
          trialApplication={applicationDeletionName}
        />
        {/* DASH */}
        <Grid item xs={12} style={{ height: dashHeight }}>
          <div style={{ width: '100%' }}>
            <ByShipToUserSelectModal
              handleUsernameSearch={handleShipToUserSearch}
              setUsernameSearchTerm={setSearchTerm}
            />
            <TrialsMenuComponent
              searchTerm={searchTerm}
              handleAddTrial={handleAddTrial}
              handleRemoveTrial={handleRemoveTrial}
              handleChange={handleChange}
              handleSearch={handleSearch}
              selectedRow={selectedRow}
              readOnlyAccess={readOnlyAccess}
              isLoading={isLoading}
              userSelected={userSelected}
              ownedUserTrials={userOwnedTrials}
            />
            <Box height={350}>
              <ClickAwayListener onClickAway={() => setSelectedRow([])}>
                <DataGrid
                  components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                  loading={isLoading}
                  rows={rows}
                  columns={columns}
                  selectionModel={selectedRow}
                  disableSelectionOnClick
                  hideFooter
                  disableColumnSelector
                  disableColumnFilter
                />
              </ClickAwayListener>
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
});

export default TrialsView;
