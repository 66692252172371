import {
  Button, Grid, styled, Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { useHistory } from 'react-router-dom';
import FullWidthCenteredLayout from '../layouts/FullWidthCenteredLayout';

const BackButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ForbiddenView = (): ReactElement => {
  document.title = 'Gems Admin Portal - Forbidden';
  const history = useHistory();
  return (
    <FullWidthCenteredLayout direction="column" marginTop={10}>
      <Grid container style={{ textAlign: 'center' }} justifyContent="center">
        <Grid item xs={12}>
          <ReportProblemRoundedIcon fontSize="large" color="error" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline">
            Invalid Permissions To View This Page.
          </Typography>
        </Grid>
      </Grid>
    </FullWidthCenteredLayout>
  );
};

export default ForbiddenView;
