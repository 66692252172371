import {
  Box, Container, Divider, Fade, Typography,
} from '@mui/material';
import { FlexboxProps, margin } from '@mui/system';
import { FunctionComponent } from 'react';

export interface IPageWithSubTitleLayout {
  marginTop: number;
  direction: string;
  subTitle: string;
}
const PageWithSubTitleLayout: FunctionComponent<IPageWithSubTitleLayout> = ({
  children,
  marginTop,
  direction,
  subTitle,
}) => (
  <Box
    sx={{
      margin: marginTop,
      display: 'flex',
      flexDirection: direction as FlexboxProps,
    }}
  >
    <Box sx={{ padding: '10px', marginBottom: '4px' }}>
      <Fade in>
        <Typography variant="overline" fontSize={18} color="secondary">
          {subTitle}
        </Typography>
      </Fade>
      <Divider variant="middle" />
    </Box>
    {children}
  </Box>
);

export default PageWithSubTitleLayout;
