import {
  FunctionComponent, MouseEvent, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLazyQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import FullWidthCenteredLayout from '../layouts/FullWidthCenteredLayout';
import { LOGIN_CREDENTIALS } from '../graphql/queries/authenticationQueries';
import { useRootStore } from '../store/Context';
import { IAuthenticateResponse } from '../graphql/types/authenticationTypes';

const LoginView: FunctionComponent = observer(() => {
  const history = useHistory();
  const { userStore, authenticationStore } = useRootStore();
  // store user password in local state, we dont want to hang onto this
  const [userPassword, setUserPassword] = useState('');

  // gql lazy query, loads username from user store,
  // and password from local component state
  const [loginWithCreds, { loading, data, error }] = useLazyQuery(LOGIN_CREDENTIALS, {
    fetchPolicy: 'network-only',
  });

  // handle gql hook values here,
  // we do it in use effect to prevent infinite loop of
  // re-renders on the component.
  useEffect(() => {
    if (data && data.loginWithCreds) {
      const loginData = data.loginWithCreds as IAuthenticateResponse;
      // store the accessToken, refreshToken and flip authenticated to true
      authenticationStore.setAccessTokenValue(loginData.AccessToken);
      authenticationStore.setRefreshToken(loginData.RefreshToken);
      // number of seconds before the accessToken expires
      authenticationStore.setExpirationTime(loginData.ExpiresIn);
      authenticationStore.setAuthentication(true);
      userStore.storeUsernameInLocalStorage();
      // redirect to dashboard
      history.push('/dashboard/user/trials');
    }
    if (error) {
      toast.error(error.message, { theme: 'colored' });
    }
  }, [
    data,
    error,
    loading,
    userStore,
    authenticationStore,
    history,
  ]);

  // executes the lazy query
  const handleLogin = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    loginWithCreds({
      variables: {
        username: userStore.getUsername,
        password: userPassword,
      },
    });
  };

  return (
    <>
      <FullWidthCenteredLayout direction="column" marginTop={10}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon color="secondary" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username"
                name="username"
                autoFocus
                value={userStore.getUsername}
                onChange={(event) => {
                  userStore.setUsername(event.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={userPassword}
                onChange={(event) => {
                  setUserPassword(event.target.value);
                }}
              />
              <Button
                fullWidth
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLogin}
              >
                {loading ? (
                  <CircularProgress size={24} color="info" />
                ) : (
                  'Sign In'
                )}
              </Button>
            </Box>
          </Box>
        </Container>
      </FullWidthCenteredLayout>
    </>
  );
});

export default LoginView;
