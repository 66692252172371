import { useHistory } from 'react-router-dom';
import { useRootStore } from '../../store/Context';

const ProtectedRoute: React.FC = ({ children }) => {
  const history = useHistory();
  const { authenticationStore } = useRootStore();
  if (!authenticationStore.isUserAuthenticated) {
    // do something;
    history.push('/forbidden');
  }
  return (
    <>
      {children}
    </>
  );
};

export default ProtectedRoute;
