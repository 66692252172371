import { createTheme } from '@material-ui/core/styles';

export const themeOptions = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#03a9f4',
    },
    secondary: {
      main: '#80d8ff',
    },
    error: {
      main: '#f44336',
    },
    success: {
      main: '#9ccc65',
    },
  },
});
